/*$pressblock_faq_wrapper_margins: (
"xs": 2rem 0 2rem 0,
"sm": 2rem 0 2rem 0,
"md": 2rem 0 2rem 0,
"lg": 0rem 0 4rem 0,
"xl": 0rem 0 4rem 0,
"xxl": 0rem 0 4rem 0,
); */
/* $faq_wrapper_margins: (
"xs": 2rem 0 2rem 0,
"sm": 2rem 0 2rem 0,
"md": 2rem 0 2rem 0,
"lg": 0rem 0 4rem 0,
"xl": 0rem 0 4rem 0,
"xxl": 0rem 0 4rem 0,
);*/
/*AL*/
.error-text {
  color: #fff;
  padding-bottom: 4rem;
  line-height: 1.35;
}
.error-text h1 {
  font-family: Bebas Neue;
  margin-bottom: 1rem;
}
@media (min-width: 0) {
  .error-text h1 {
    font-size: 2rem;
  }
}
@media (min-width: 576px) {
  .error-text h1 {
    font-size: 2.5rem;
  }
}
@media (min-width: 768px) {
  .error-text h1 {
    font-size: 3rem;
  }
}
@media (min-width: 992px) {
  .error-text h1 {
    font-size: 3.5rem;
  }
}
@media (min-width: 1200px) {
  .error-text h1 {
    font-size: 4rem;
  }
}
@media (min-width: 1400px) {
  .error-text h1 {
    font-size: 4rem;
  }
}
.error-text h2 {
  margin-bottom: 1rem;
}
@media (min-width: 0) {
  .error-text h2 {
    font-size: 1.45em;
  }
}
@media (min-width: 576px) {
  .error-text h2 {
    font-size: 1.45em;
  }
}
@media (min-width: 768px) {
  .error-text h2 {
    font-size: 1.45em;
  }
}
@media (min-width: 992px) {
  .error-text h2 {
    font-size: 1.55em;
  }
}
@media (min-width: 1200px) {
  .error-text h2 {
    font-size: 1.65em;
  }
}
@media (min-width: 1400px) {
  .error-text h2 {
    font-size: 1.7em;
  }
}
.error-text a {
  color: #ddd;
  text-decoration: none;
}
.error-text a:hover, .error-text a:focus {
  color: #fff;
}
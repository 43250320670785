  @use "../variables" as vars;
@use "../mixins" as mixins;

.error-text {
    color: map-get(vars.$colors, "white");
    padding-bottom: 4rem;
    line-height: 1.35;
    & h1 {
        font-family: Bebas Neue;
        @include mixins.responsive(
            font-size,
            vars.$legal_page_title_font_sizes
        );
        margin-bottom: 1rem;
    }
    & h2 {
        @include mixins.responsive(font-size, vars.$legal_page_h2_font_sizes);
        margin-bottom: 1rem;
    }
    & a {
        color: map-get(vars.$colors, "light-grey");
        text-decoration: none;
        &:hover,
        &:focus {
            color: map-get(vars.$colors, "white");
        }
    }
}
